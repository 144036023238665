import { XMarkIcon } from '@heroicons/react/24/solid'
import {
  Button,
  SequenceMenu,
  SequenceMenuItem
} from '@sequencehq/core-components'
import {
  AttachmentReducerAction,
  AttachmentReducerState
} from './attachmentReducer'
import { FC } from 'react'
import { formatFileSizeToHumanReadable } from '@sequencehq/formatters'
import { PdfPreview } from './PdfPreview'
import { Box, Center, Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid'

export const AttachmentPreview: FC<{
  state: AttachmentReducerState
  dispatch: React.Dispatch<AttachmentReducerAction>
  disableControls?: boolean
}> = ({ state, dispatch, disableControls }) => {
  const { attachmentToPreview: attachment } = state

  if (!attachment) {
    return null
  }

  return (
    <Flex flexDirection="column" height="100%" gap="16px">
      <Header
        onClickClose={() => {
          dispatch({ type: 'PREVIEW_ATTACHMENT', attachment: null })
        }}
        onClickDelete={() => {
          dispatch({
            type: 'DELETE_ATTACHMENT',
            attachment
          })
        }}
        disableControls={disableControls}
        name={attachment.fileName}
        size={formatFileSizeToHumanReadable(attachment.fileSizeBytes)}
      />
      <Box flexGrow={1}>
        <PdfPreview url={attachment.url} />
      </Box>
    </Flex>
  )
}

const Header: FC<{
  name: string
  size: string
  onClickClose: VoidFunction
  onClickDelete: VoidFunction
  disableControls?: boolean
}> = ({ name, size, onClickClose, onClickDelete, disableControls }) => {
  return (
    <Flex justifyContent="space-between" px="16px">
      <Flex gap="4px" flexDirection="column">
        <Text {...Lato13Bold} color={GreyGrey80}>
          {name}
        </Text>
        <Text {...Lato13Regular} color={GreyGrey60}>
          {size}
        </Text>
      </Flex>
      <Flex gap="8px">
        <SequenceMenu
          alignment="bottom-right"
          menuButton={
            <Button variant="ghost" size="24px">
              <Center>
                <EllipsisHorizontalIcon
                  width="16px"
                  height="16px"
                  color={GreyGrey60}
                />
              </Center>
            </Button>
          }
          items={[
            <SequenceMenuItem
              key="delete"
              uuid="delete"
              label="Delete attachment"
              isDisabled={!!disableControls}
              onClick={e => {
                e.stopPropagation()
                onClickDelete()
              }}
              style={{ color: 'red' }}
            />
          ]}
        />
        <Button
          variant="ghost"
          size="24px"
          onClick={onClickClose}
          aria-label="Close preview"
        >
          <Center>
            <XMarkIcon width="16px" height="16px" color={GreyGrey60} />
          </Center>
        </Button>
      </Flex>
    </Flex>
  )
}
