import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { Currency, useQuery } from '@sequencehq/api/utils'
import { getLastDateOfMonth, StringDate, toStringDate } from '@sequencehq/utils'
import { createDateFromBucket } from './graphUtils'
import invariant from 'tiny-invariant'

interface RecognizedRevenueForMonth {
  date: StringDate
  endDate: StringDate
  subValues: {
    recognized: number
    deferred: number
  }
  value: number
}

interface Data {
  startDate: StringDate
  endDate: StringDate
  recognizedRevenueByMonth: Array<RecognizedRevenueForMonth>
  total: number
}
/**
 * Returns the last 12 months of data for the given currency
 * @returns
 */
export const useRecognizedRevenueData = (props: {
  currency?: Currency | null
}):
  | {
      isLoading: true
      data: null
    }
  | {
      isLoading: false
      data: Data
    } => {
  const recognizedRevenueResponse = useQuery(
    dashboardv99990101Client.getRevRecChartsData,
    {
      currency: props.currency ?? 'GBP'
    },
    {
      enabled: !!props.currency,
      staleTime: 60000,
      select: data => {
        if (!data) {
          return
        }

        const recognizedRevenueByMonth = data.period.buckets.map(
          (bucket): RecognizedRevenueForMonth => {
            const recognizedRevenue =
              bucket.recognizedAndDeferred.ledgerAccountTotals.find(
                i => i.name === 'RECOGNIZED_REVENUE'
              )?.total || 0
            const deferredRevenue =
              bucket.recognizedAndDeferred.ledgerAccountTotals.find(
                i => i.name === 'DEFERRED_REVENUE'
              )?.total || 0

            const startDate = createDateFromBucket({
              year: bucket.year,
              month: bucket.month
            })

            return {
              date: startDate,
              endDate: getLastDateOfMonth(startDate),
              subValues: {
                recognized: recognizedRevenue,
                deferred: deferredRevenue
              },
              value: bucket.recognizedAndDeferred.total
            }
          }
        )

        return {
          recognizedRevenueByMonth,
          total: data.period.totals.recognizedAndDeferred,
          startDate: toStringDate(data.period.startDate),
          endDate: toStringDate(data.period.endDate)
        }
      }
    }
  )

  if (recognizedRevenueResponse.isError) {
    throw new Error('Failed to load recognized revenue data')
  }

  if (recognizedRevenueResponse.isPending) {
    return {
      isLoading: true,
      data: null
    }
  }

  invariant(
    recognizedRevenueResponse.data,
    'Recognized revenue should be defined, if loaded'
  )

  return {
    data: {
      recognizedRevenueByMonth:
        recognizedRevenueResponse.data.recognizedRevenueByMonth,
      total: recognizedRevenueResponse.data.total,
      startDate: recognizedRevenueResponse.data.startDate,
      endDate: recognizedRevenueResponse.data.endDate
    },
    isLoading: false
  }
}
