import { useLayoutEffect, useState } from 'react'
import { useExpanded } from './selectorHooks'
import { JournalReportExpandedTable } from './JournalReportExpandedTable'
import { JournalReportCollapsedTable } from './JournalReportCollapsedTable'

export const JournalReportTable = () => {
  const { expanded } = useExpanded()
  const [firstRenderDone, setFirstRenderDone] = useState<boolean>(false)

  /**
   * We want to 'pulse' some of the columns in the expanded view (the 'new' ones)
   * when we swap from collapsed to expanded, but _only_ when swapping! We don't
   * want to pulse the columns if we load straight into the expanded table.
   *
   * To manage this, we keep track of the first render, and we will only 'pulse' the
   * columns in the expanded table if we mount the table after the first render has
   * finished (i.e. we started with a collapsed table).
   */
  useLayoutEffect(() => {
    if (!firstRenderDone) {
      setFirstRenderDone(true)
    }
  }, [firstRenderDone, setFirstRenderDone])

  if (expanded) {
    return (
      <JournalReportExpandedTable
        pulseExpandedColumnsOnMount={firstRenderDone && expanded}
      />
    )
  }

  return <JournalReportCollapsedTable />
}
