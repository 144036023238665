import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { useCallback, useMemo, useState } from 'react'
import { Product } from 'Products/types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useQuery } from '@sequencehq/api/utils'

type ProductField = Pick<Product, 'name' | 'label' | 'taxCategoryId'>

export const useAddProductForm = () => {
  const flags = useFlags()

  const { data: taxCategoriesData } = useQuery(
    dashboard20240730Client.getTaxCategories
  )

  const [product, setProduct] = useState<ProductField>()

  const taxCategories = taxCategoriesData?.items ?? []

  const defaultTaxCategory = taxCategories.find(({ isDefault }) => isDefault)

  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const { fields, queries } = useForm<ProductField>({
    value: {
      label: '',
      name: '',
      taxCategoryId: defaultTaxCategory?.id
    },
    showValidationErrors,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required]
      },
      {
        property: 'label',
        validation: []
      },
      {
        property: 'taxCategoryId',
        validation: flags.showNewTaxRatesSettings ? [required] : []
      }
    ],
    onChange: newData => {
      setShowValidationErrors(false)
      setProduct(newData)
    }
  })

  const onSubmit = useCallback(() => {
    if (!queries.isValid) {
      setShowValidationErrors(true)
      return
    }

    if (!product) {
      return
    }

    return product
  }, [product, queries.isValid])

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      taxCategoryId: {
        ...fields.taxCategoryId,
        hidden: !flags.showNewTaxRatesSettings || taxCategories.length === 0,
        options: taxCategories.map(category => ({
          value: category.id,
          label: category.name
        }))
      }
    }
  }, [fields, flags.showNewTaxRatesSettings, taxCategories])

  return {
    fieldsConfig: enhancedFields,
    onSubmit
  }
}
