import { Center, Flex, Tooltip } from '@chakra-ui/react'
import { HashtagIcon, IdentificationIcon } from '@heroicons/react/16/solid'
import BuildingOffice2Icon from '@heroicons/react/24/outline/BuildingOffice2Icon'
import { Badge, KebabMenu, MenuItemBuilder } from '@sequencehq/core-components'
import { countries, CustomerModel } from '@sequencehq/core-models'
import { borderColor } from '@sequencehq/core-theme'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTableCell,
  MagicTableCellEmpty,
  MagicTableCellLink,
  MagicTableFilterConfig,
  MagicTableV2,
  useMagicTableInfiniteQuery
} from '@sequencehq/tables'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { CustomersMagicTableTypeahead } from 'components/Customers/CustomersMagicTableTypeahead'
import {
  Customer,
  CustomersFilters,
  CustomersView
} from 'components/Customers/types'
import {
  menuItemBuilders,
  useArchiveBuilder,
  useDetailBuilder
} from 'components/Customers/utils/buildMenuItems'
import { getCustomerRole } from 'components/Customers/utils/getCustomerRole'
import EmptyState from 'components/Loading/EmptyState'
import Spinner from 'components/Loading/Spinner'
import { useGetSequenceAccountIdQuery } from 'features/api'
import { OverlayCreateCustomerModel } from 'features/overlay/overlay'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { addPaddingToIntegrationLogo, getLogo } from 'lib/integrations/logos'
import { useFetchIntegrations } from 'Customer/hooks/useFetchIntegrations'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { invalidateCustomersCache } from './hooks/useCustomersRoot'

const tableFilters: MagicTableFilterConfig<CustomersFilters>[] = [
  {
    type: 'text',
    paramName: 'legalName',
    label: 'Name',
    typeahead: (value, setValue) => (
      <CustomersMagicTableTypeahead value={value} setValue={setValue} />
    ),
    icon: IdentificationIcon
  },
  {
    type: 'text',
    paramName: 'alias',
    label: 'Alias',
    icon: HashtagIcon
  },
  {
    paramName: 'includeArchived',
    type: 'toggle',
    label: 'Show archived customers'
  }
]

export const CustomersMagicTable = () => {
  const flags = useFlags()

  const { infiniteQuery, setQueryParams } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getCustomers
  )

  const {
    data: sequenceAccountIdResponse,
    isFetching: isFetchingSequenceAccountId
  } = useGetSequenceAccountIdQuery(undefined)

  const sequenceAccountId = sequenceAccountIdResponse?.value()

  const { connectedIntegrations } = useFetchIntegrations()

  if (
    !connectedIntegrations ||
    isFetchingSequenceAccountId ||
    !sequenceAccountId
  ) {
    return (
      <Center width="100%" height="100vh">
        <Spinner />
      </Center>
    )
  }

  const modalContent: OverlayCreateCustomerModel = {
    content: 'createCustomerModal',
    data: {}
  }

  const emptyContent = {
    title: 'Add or import customers',
    description:
      'Add new customers using the dashboard or import from your CRM or Accounting suite.',
    linkCopy: 'Learn more about importing customers.',
    docLink: `https://docs.sequencehq.com/customers/create-customer`,
    overlayPayload: modalContent,
    buttonCopy: 'Add new customer'
  }

  return (
    <CurrentUserId>
      {userId => (
        <MagicTableV2<CustomersView>
          infiniteQuery={infiniteQuery}
          onQueryParamsChanged={setQueryParams}
          entityNamePlural="customers"
          entityIcon={props => <BuildingOffice2Icon {...props} />}
          sequenceUserId={userId}
          rowPath={(row: CustomersView['model']) => `/customers/${row.id}`}
          columns={[
            {
              id: 'legalName',
              header: 'Name',
              accessorFn: row => ({
                customerId: row.id,
                customerName: row.legalName,
                archivedAt: row.archivedAt,
                organizations: row.organizations
              }),
              cell: value => {
                const { customerId, customerName, archivedAt, organizations } =
                  value.getValue<{
                    customerId: CustomerModel['id']
                    customerName: CustomerModel['legalName']
                    archivedAt: CustomerModel['archivedAt']
                    organizations: Customer['organizations']
                  }>()

                if (flags?.customerParentChildRelationship) {
                  const customerRole = getCustomerRole({
                    organizations,
                    customerId
                  })

                  return (
                    <Flex alignItems="center" justifyContent="space-between">
                      <MagicTableCellLink
                        to={`/customers/${customerId}`}
                        label={`${customerName}${
                          archivedAt ? ' (archived)' : ''
                        }`}
                      />
                      {customerRole && (
                        <Badge sentiment="neutral" size="sm">
                          {customerRole}
                        </Badge>
                      )}
                    </Flex>
                  )
                } else {
                  return (
                    <MagicTableCellLink
                      to={`/customers/${customerId}`}
                      label={`${customerName}${
                        archivedAt ? ' (archived)' : ''
                      }`}
                    />
                  )
                }
              },
              enableSorting: true
            },
            {
              id: 'addressCountry',
              header: 'Country',
              accessorFn: (row: CustomersView['model']) => ({
                countryCode: row.address.country,
                archivedAt: row.archivedAt
              }),
              cell: row => {
                const { countryCode, archivedAt } = row.getValue<{
                  countryCode: CustomersView['model']['address']['country']
                  archivedAt: CustomersView['model']['archivedAt']
                }>()

                return (
                  <MagicTableCell
                    textColor={archivedAt && GreyGrey60}
                    text={countries[countryCode] || countryCode}
                  />
                )
              },
              enableSorting: true
            },
            {
              id: 'createdAt',
              header: 'Created',
              accessorFn: (row: CustomersView['model']) => ({
                createdAt: row.createdAt,
                archivedAt: row.archivedAt
              }),
              cell: row => {
                const { createdAt, archivedAt } = row.getValue<{
                  createdAt: CustomersView['model']['createdAt']
                  archivedAt: CustomersView['model']['archivedAt']
                }>()

                return (
                  <MagicTableCell
                    textColor={archivedAt && GreyGrey60}
                    text={dateTimeWithFormat(createdAt, 'd MMM yyyy')}
                  />
                )
              },
              enableSorting: true
            },
            {
              id: 'integrationIds',
              header: 'Links',
              accessorKey: 'integrationIds',
              cell: row => {
                const integrationIds =
                  row.getValue<CustomersView['model']['integrationIds']>()

                if (!integrationIds?.length) {
                  return null
                }

                return (
                  <MagicTableCellEmpty>
                    <Flex gap="4px" overflow="hidden">
                      {integrationIds.map(linkedService => (
                        <Tooltip
                          key={linkedService.service}
                          label={linkedService.service.replace(/_/g, '')}
                        >
                          <Center
                            boxSize="24px"
                            bg={borderColor}
                            borderRadius="24px"
                          >
                            {getLogo(
                              linkedService.service,
                              addPaddingToIntegrationLogo(linkedService.service)
                                ? 18
                                : undefined
                            )}
                          </Center>
                        </Tooltip>
                      ))}
                    </Flex>
                  </MagicTableCellEmpty>
                )
              }
            },
            {
              id: 'label',
              header: 'Label',
              accessorKey: 'label',
              cell: row => (
                <MagicTableCell
                  text={row.getValue<CustomersView['model']['label']>()}
                />
              ),
              enableSorting: false,
              hiddenByDefault: true
            }
          ]}
          filters={tableFilters}
          emptyState={props => (
            <EmptyState emptyContent={emptyContent} {...props} />
          )}
          kebabMenu={(model: CustomersView['model'], props) => {
            return (
              <KebabMenu
                menuItems={[
                  useDetailBuilder,
                  ...menuItemBuilders({
                    onLink: invalidateCustomersCache,
                    onUnlink: invalidateCustomersCache
                  })(
                    connectedIntegrations.map(
                      integration => integration.service
                    ) || []
                  ),
                  useArchiveBuilder
                ].map((builder: MenuItemBuilder<CustomersView['model']>) =>
                  builder(model, {
                    onSuccess: invalidateCustomersCache
                  })
                )}
                renderListInPortal={true}
                flags={flags}
                {...props}
              />
            )
          }}
        />
      )}
    </CurrentUserId>
  )
}
