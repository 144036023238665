import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey60,
  GreyGrey70,
  GreyGrey90,
  Lato13Bold,
  Lato13Regular,
  Lato20Bold
} from '@sequencehq/design-tokens'
import { XYChart } from '@sequencehq/shared-components'
import { currencyToSymbol, toMoney } from '@sequencehq/core-models'
import { ChartTooltip } from 'components/InsightsV2/common/ChartTooltip'
import { formatStringDate, toStringDate } from '@sequencehq/utils'
import { Card, Skeleton } from '@sequencehq/core-components'
import { calculateYAxisLabels, formatTotal } from './graphUtils'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import { useInvoicedRevenueData } from './useInvoicedRevenueData'

/**
 * The tooltip displayed by the XYChart component when hovering over
 * different bars in the chart.
 * @param date - the formatted date of the item
 * @param value - the value to display
 * @returns
 */
const GraphDataTooltip = ({
  date,
  values
}: {
  date: string
  values: {
    billed: string
    unbilled: string
    total: string
  }
}) => {
  return (
    <ChartTooltip>
      <Text {...Lato13Bold} color={GreyGrey70}>
        {date}
      </Text>
      <Box width="100%">
        <Flex
          width="100%"
          justifyContent="space-between"
          borderBottom={`1px solid ${GreyGrey30}`}
          paddingBottom="8px"
        >
          <Text {...Lato13Regular} color={GreyGrey70}>
            Billed
          </Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {values.billed}
          </Text>
        </Flex>
        <Flex
          width="100%"
          justifyContent="space-between"
          borderBottom={`1px solid ${GreyGrey30}`}
          padding="8px 0"
        >
          <Text {...Lato13Regular} color={GreyGrey70}>
            Not billed
          </Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {values.unbilled}
          </Text>
        </Flex>
        <Flex width="100%" justifyContent="space-between" padding="8px 0 4px">
          <Text {...Lato13Bold} color={GreyGrey70}>
            Total
          </Text>
          <Text {...Lato13Bold} color={GreyGrey90}>
            {values.total}
          </Text>
        </Flex>
      </Box>
    </ChartTooltip>
  )
}

interface EventTypesWidgetProps {
  currency: Currency
  yAxisLabels: number[]
}
/**
 * Displays the invoiced revenue over a given date range and currency.
 *
 * This graph is a modified version of the graph seen in the invoices page, with some
 * features removed and some general small tweaks.
 * @param param0
 * @returns
 */
export const InvoicedRevenueGraph = ({
  currency,
  yAxisLabels
}: EventTypesWidgetProps) => {
  const { isLoading, data } = useInvoicedRevenueData({
    currency
  })

  if (isLoading) {
    return (
      <Skeleton minWidth="390px" minHeight="291px" width="100%" height="100%" />
    )
  }

  return (
    <Card
      minWidth="390px"
      width="100%"
      data-testid="revrec.home.invoicedRevenueGraph"
    >
      <Grid rowGap="8px" marginBottom="16px">
        <Flex flexDirection="column" gap="8px">
          <Text {...Lato13Regular} color={GreyGrey60}>
            Invoiced revenue
          </Text>
          <Text {...Lato20Bold} color={GreyGrey90}>
            {formatTotal({ currency, value: data.total })}
          </Text>
          <Text {...Lato13Regular} color={GreyGrey60}>
            {formatStringDate(data.startDate, 'd MMM yyyy')} -{' '}
            {formatStringDate(data.endDate, 'd MMM yyyy')}
          </Text>
        </Flex>
      </Grid>
      <Box padding="8px">
        <Box height="155px" width="100%">
          <XYChart<{
            date: string
            value: number
            subValues: { billed: number; unbilled: number }
          }>
            variant="bar"
            dateFrom={data.startDate}
            dateTo={data.endDate}
            data={data.invoicedRevenueByMonth}
            unitTickValues={
              yAxisLabels ??
              calculateYAxisLabels(
                data.invoicedRevenueByMonth.map(({ value }) => value)
              )
            }
            dateFormat="d MMM yyyy"
            unit={currencyToSymbol[currency]}
            animate={false}
            renderTooltip={({ date, value, subValues }) => (
              <GraphDataTooltip
                date={formatStringDate(toStringDate(date), 'MMMM yyyy')}
                values={{
                  billed: toMoney({
                    value: subValues.billed.toString(),
                    currency
                  }),
                  unbilled: toMoney({
                    value: subValues.unbilled.toString(),
                    currency
                  }),
                  total: toMoney({
                    value: value.toString(),
                    currency
                  })
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Card>
  )
}
