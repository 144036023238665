import { useCallback } from 'react'
import {
  usePostTaxRegistrationsMutation,
  usePutTaxRegistrationsByIdMutation
} from 'features/api'
import { TaxRegistrationModel } from '@sequencehq/core-models'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { CountriesAlpha2, useMutation } from '@sequencehq/api/dist/utils'

type Customer = DashboardApi20240730.GetCustomer.Customer

type UpdateCustomerData = DashboardApi20240730.PutCustomer.PutCustomerBody & {
  taxRegistration?: {
    id?: string
    identifier: string
    country: CountriesAlpha2
  }
}

export const useSaveCustomer = () => {
  const putCustomerMutator = useMutation(dashboard20240730Client.putCustomer)

  const [postTaxRegistrationMutator] = usePostTaxRegistrationsMutation()
  const [putTaxRegistrationMutator] = usePutTaxRegistrationsByIdMutation()

  const createOrUpdateTaxRegistration = useCallback(
    async (
      customerId: string,
      taxRegistration: NonNullable<UpdateCustomerData['taxRegistration']>
    ): Promise<{
      taxRegistration: TaxRegistrationModel | null
      success: boolean
    }> => {
      const taxRegistrationArgs = {
        customerId: customerId,
        country: taxRegistration.country,
        taxIdentifier: taxRegistration.identifier
      }

      const taxRegistrationResponse = taxRegistration.id
        ? await putTaxRegistrationMutator({
            id: taxRegistration.id,
            updateTaxRegistrationEndpointUpdateTaxRegistrationRequestModel:
              taxRegistrationArgs
          })
        : await postTaxRegistrationMutator({
            createTaxRegistrationEndpointCreateTaxRegistrationRequestModel:
              taxRegistrationArgs
          })

      if ('error' in taxRegistrationResponse) {
        return {
          taxRegistration: null,
          success: false
        }
      }

      const value = taxRegistrationResponse.data.value()

      return {
        taxRegistration: value ? { ...value, customerId } : null,
        success: true
      }
    },
    [postTaxRegistrationMutator, putTaxRegistrationMutator]
  )

  const update = useCallback(
    (customerId: string) =>
      async (
        customer: UpdateCustomerData
      ): Promise<{
        customer: Customer | null
        success: boolean
      }> => {
        const customerResponse = await putCustomerMutator.mutateAsync({
          id: customerId,
          body: {
            legalName: customer.legalName,
            contacts: customer.contacts,
            customerAliases: customer.customerAliases,
            address: customer.address,
            taxStatus: customer.taxStatus,
            label: customer.label
          }
        })

        if (customer.taxRegistration) {
          const taxRegistrationResult = await createOrUpdateTaxRegistration(
            customerId,
            customer.taxRegistration
          )

          return {
            customer: customerResponse,
            success: taxRegistrationResult.success
          }
        }

        return {
          customer: customerResponse,
          success: true
        }
      },
    [createOrUpdateTaxRegistration, putCustomerMutator]
  )

  const updateWithIntegrations = useCallback(
    (customerId: string) =>
      async (
        customer: UpdateCustomerData & {
          integrationIds: NonNullable<UpdateCustomerData['integrationIds']>
        }
      ): Promise<{
        customer: Customer | null
        success: boolean
      }> => {
        const customerResponse = await putCustomerMutator.mutateAsync({
          id: customerId,
          body: {
            legalName: customer.legalName,
            contacts: customer.contacts,
            customerAliases: customer.customerAliases,
            address: customer.address,
            taxStatus: customer.taxStatus,
            integrationIds: customer.integrationIds,
            label: customer.label
          }
        })

        return {
          customer: customerResponse,
          success: true
        }
      },
    [putCustomerMutator]
  )

  return {
    update,
    updateWithIntegrations
  }
}
