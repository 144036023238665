import {
  InvoiceEditorReducerState,
  RecursivePartial
} from 'InvoiceEditor/domainManagement/invoiceEditor.types.ts'
import { useCallback, useReducer } from 'react'
import { invoiceEditorReducer } from 'InvoiceEditor/domainManagement/invoiceEditorReducer.ts'
import { INITIAL_REDUCER_STATE } from 'InvoiceEditor/domainManagement/invoiceEditor.constants.ts'
import { dequal } from 'dequal'
import {
  Currency,
  IntegrationService,
  InvoiceModel,
  InvoicePaymentStatus,
  PaymentProvider
} from '@sequencehq/core-models'
import * as Sentry from '@sentry/react'
import { debounce } from 'lodash'
import { AdapterCustomerWithTaxModel } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'

export type InvoiceEditor = {
  data: InvoiceEditorReducerState['data']
  configuration: InvoiceEditorReducerState['configuration']
  derived: InvoiceEditorReducerState['derived']
  editor: InvoiceEditorReducerState['editor']
  functions: {
    addCollection: (
      paymentProvider: PaymentProvider,
      existingPaymentOptions: InvoiceEditor['data']['invoice']['paymentOptions']
    ) => void
    createCreditNote: () => void
    createLineItemGroup: (
      lineItemGroupId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => void
    createLineItem: (
      lineItemGroupId: string,
      lineItemId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => void
    deleteLineItem: (
      lineItemGroupId: string,
      lineItemId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => void
    deleteLineItemGroup: (
      lineItemGroupId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => void
    downloadInvoice: () => void
    showEditCustomerForm: () => void
    finaliseAndSendInvoice: () => void
    finaliseInvoice: () => void
    hasInitializationDataChanged: (
      newInitializationData: Pick<
        InvoiceEditorReducerState,
        'data' | 'configuration'
      >
    ) => boolean
    linkCustomerToIntegration: (integrationService: IntegrationService) => void
    loadData: (args: {
      data: InvoiceEditorReducerState['data']
      configuration: Partial<InvoiceEditorReducerState['configuration']>
    }) => void
    recalculateInvoice: () => void
    resetData: (data: Partial<InvoiceEditorReducerState['data']>) => void
    saveInvoice: () => void
    sendInvoice: () => void
    sendPaymentReminder: () => void
    sendTestInvoice: () => void
    showPaymentDetailsDrawer: () => void
    syncInvoiceToIntegration: (
      integrationService: IntegrationService,
      existingLinkedService: InvoiceEditorReducerState['data']['invoice']['linkedServices']
    ) => void
    updateCustomer: (customer: AdapterCustomerWithTaxModel) => void
    showInvoicePdfPreviewDrawer: () => void
    updateData: (
      data: RecursivePartial<InvoiceEditorReducerState['data']>
    ) => void
    updateLineItemGroup: (
      lineItemGroupId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => void
    updateLineItem: (
      lineItemGroupId: string,
      lineItemId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => void
    updatePaymentStatus: (paymentStatus: InvoicePaymentStatus) => void
    voidInvoice: () => void
    updateMemo: (
      reducerState: InvoiceEditorReducerState['data'],
      newMemo: InvoiceModel['memo']
    ) => void
    updateDueDate: (
      reducerState: InvoiceEditorReducerState['data'],
      newDueDate: string | undefined
    ) => void
    updatePurchaseOrderNumber: (
      reducerState: InvoiceEditorReducerState['data'],
      newPurchaseOrderNumber: string | undefined
    ) => void
    updateReference: (
      reducerState: InvoiceEditorReducerState['data'],
      newReference: string | undefined
    ) => void
  }
}

export type RecalculateInvoiceRefreshPayload = {
  totals: InvoiceEditorReducerState['data']['totals']
  lineItems: InvoiceEditorReducerState['data']['lineItems']
  lineItemGroups: InvoiceEditorReducerState['data']['lineItemGroups']
  lineItemGroupUsage: InvoiceEditorReducerState['data']['lineItemGroupUsage']
  subAccountUsageBreakdown: InvoiceEditorReducerState['data']['subAccountUsageBreakdown']
  calculatedAt: InvoiceEditorReducerState['data']['invoice']['calculatedAt']
}

type UseInvoiceEditor = (props: {
  onAddCollection: (
    PaymentProvider: PaymentProvider,
    existingPaymentOptions: InvoiceEditorReducerState['data']['invoice']['paymentOptions']
  ) => Promise<void | RecursivePartial<InvoiceEditorReducerState['data']>>
  onCreateCreditNote: (
    currency: Currency,
    customerId: string
  ) => Promise<{ creditNote: { id: string } } | void>
  onUpdateCreditNoteLineItems: (creditNoteId: string) => Promise<void>
  onCreateLineItemGroup: (
    lineItemGroupId: string,
    reducerData: InvoiceEditorReducerState['data']
  ) => Promise<void | Partial<InvoiceEditorReducerState['data']>>
  onCreateLineItem: (
    lineItemGroupId: string,
    lineItemId: string,
    reducerData: InvoiceEditorReducerState['data']
  ) => Promise<void | Partial<InvoiceEditorReducerState['data']>>
  onDeleteLineItem: (
    lineItemGroupId: string,
    lineItemId: string,
    reducerData: InvoiceEditorReducerState['data']
  ) => Promise<void | Partial<InvoiceEditorReducerState['data']>>
  onDeleteLineItemGroup: (
    lineItemGroupId: string,
    reducerData: InvoiceEditorReducerState['data']
  ) => Promise<void | Partial<InvoiceEditorReducerState['data']>>
  onFinaliseAndSendInvoice: () => Promise<void | RecursivePartial<
    InvoiceEditorReducerState['data']
  >>
  onFinaliseInvoice: () => Promise<void | RecursivePartial<
    InvoiceEditorReducerState['data']
  >>
  onLinkCustomerToIntegration: (
    integrationService: IntegrationService
  ) => Promise<void | RecursivePartial<InvoiceEditorReducerState['data']>>
  onRecalculateInvoice: () => Promise<void | RecalculateInvoiceRefreshPayload>
  onSave: () => void
  onSendInvoice: () => Promise<
    void | RecursivePartial<InvoiceEditorReducerState>['data']
  >
  onSendPaymentReminder: () => Promise<void>
  onSendTestInvoice: () => Promise<void>
  onShowEditCustomerForm: () => void
  onShowPaymentDetailsDrawer: () => void
  onSyncInvoiceToIntegration: (
    integrationService: IntegrationService,
    existingLinkedServices: InvoiceEditorReducerState['data']['invoice']['linkedServices']
  ) => Promise<void | RecursivePartial<InvoiceEditorReducerState['data']>>
  onShowInvoicePdfPreviewDrawer: () => void
  onUpdateCustomer: (
    updatedCustomer: AdapterCustomerWithTaxModel
  ) => Promise<void | RecursivePartial<InvoiceEditorReducerState['data']>>
  onUpdateLineItemGroup: (
    lineItemGroupId: string,
    reducerData: InvoiceEditorReducerState['data']
  ) => Promise<void | Partial<InvoiceEditorReducerState['data']>>
  onUpdateLineItem: (
    lineItemGroupId: string,
    lineItemId: string,
    reducerData: InvoiceEditorReducerState['data']
  ) => Promise<void | Partial<InvoiceEditorReducerState['data']>>
  onUpdatePaymentStatus: (
    paymentStatus: InvoicePaymentStatus
  ) => Promise<void | RecursivePartial<InvoiceEditorReducerState['data']>>
  onVoidInvoice: () => Promise<void | RecursivePartial<
    InvoiceEditorReducerState['data']
  >>
  onUpdateMemo: (
    reducerState: InvoiceEditorReducerState['data'],
    newMemo: InvoiceModel['memo']
  ) => Promise<void | RecursivePartial<InvoiceEditorReducerState['data']>>
  onUpdateDueDate: (
    reducerState: InvoiceEditorReducerState['data'],
    newDueDate: string | undefined
  ) => Promise<void | RecursivePartial<InvoiceEditorReducerState['data']>>
  onUpdatePurchaseOrderNumber: (
    reducerState: InvoiceEditorReducerState['data'],
    newPurchaseOrderNumber: string | undefined
  ) => Promise<void | RecursivePartial<InvoiceEditorReducerState['data']>>
  onUpdateReference: (
    reducerState: InvoiceEditorReducerState['data'],
    newReference: string | undefined
  ) => Promise<void | RecursivePartial<InvoiceEditorReducerState['data']>>
}) => InvoiceEditor

export const useInvoiceEditor: UseInvoiceEditor = props => {
  const [state, dispatch] = useReducer(
    invoiceEditorReducer,
    INITIAL_REDUCER_STATE
  )

  const loadData = useCallback(
    ({
      data,
      configuration
    }: {
      data: InvoiceEditorReducerState['data']
      configuration?: Partial<InvoiceEditorReducerState['configuration']>
    }) => {
      dispatch({
        type: 'loadInvoiceEditorData',
        payload: {
          data,
          configuration
        }
      })
    },
    []
  )

  const updateData = useCallback(
    (data: RecursivePartial<InvoiceEditorReducerState['data']>) => {
      dispatch({ type: 'updateInvoiceEditorData', payload: data })
    },
    []
  )

  const resetData = useCallback(
    (data: Partial<InvoiceEditorReducerState['data']>) => {
      dispatch({ type: 'resetInvoiceEditorData', payload: data })
    },
    []
  )

  const hasInitializationDataChanged = useCallback(
    (
      newInitializationData: Pick<
        InvoiceEditorReducerState,
        'data' | 'configuration'
      >
    ) => {
      return !dequal(newInitializationData, {
        data: state.initialData,
        configuration: state.configuration
      })
    },
    [state.initialData, state.configuration]
  )

  const sendInvoice = useCallback(() => {
    props
      .onSendInvoice()
      .then(fieldsToUpdate => {
        if (fieldsToUpdate) {
          dispatch({ type: 'updateInvoiceEditorData', payload: fieldsToUpdate })
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [props])
  const finaliseInvoice = useCallback(() => {
    props
      .onFinaliseInvoice()
      .then(fieldsToUpdate => {
        if (fieldsToUpdate) {
          dispatch({ type: 'updateInvoiceEditorData', payload: fieldsToUpdate })
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [props])
  const recalculateInvoice = useCallback(() => {
    props
      .onRecalculateInvoice()
      .then(fieldsToUpdate => {
        if (fieldsToUpdate) {
          dispatch({
            type: 'resetInvoiceEditorData',
            payload: {
              subAccountUsageBreakdown: fieldsToUpdate.subAccountUsageBreakdown,
              totals: fieldsToUpdate.totals,
              lineItems: fieldsToUpdate.lineItems,
              lineItemGroups: fieldsToUpdate.lineItemGroups,
              lineItemGroupUsage: fieldsToUpdate.lineItemGroupUsage
            }
          })

          dispatch({
            type: 'updateInvoiceEditorData',
            payload: { invoice: { calculatedAt: fieldsToUpdate.calculatedAt } }
          })
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [props])
  const voidInvoice = useCallback(() => {
    props
      .onVoidInvoice()
      .then(fieldsToUpdate => {
        if (fieldsToUpdate) {
          dispatch({ type: 'updateInvoiceEditorData', payload: fieldsToUpdate })
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [props])
  const finaliseAndSendInvoice = useCallback(() => {
    props
      .onFinaliseAndSendInvoice()
      .then(fieldsToUpdate => {
        if (fieldsToUpdate) {
          dispatch({ type: 'updateInvoiceEditorData', payload: fieldsToUpdate })
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [props])
  const sendPaymentReminder = useCallback(() => {
    props.onSendPaymentReminder().catch(e => Sentry.captureException(e))
  }, [props])
  const updatePaymentStatus = useCallback(
    (paymentStatus: InvoicePaymentStatus) => {
      props
        .onUpdatePaymentStatus(paymentStatus)
        .then(fieldsToUpdate => {
          if (fieldsToUpdate) {
            dispatch({
              type: 'updateInvoiceEditorData',
              payload: fieldsToUpdate
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  const createLineItemGroup = useCallback(
    (
      lineItemGroupId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      props
        .onCreateLineItemGroup(lineItemGroupId, reducerData)
        .then(fieldsToReplace => {
          if (fieldsToReplace) {
            dispatch({
              type: 'resetInvoiceEditorData',
              payload: fieldsToReplace
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  const createLineItem = useCallback(
    (
      lineItemGroupId: string,
      lineItemId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      props
        .onCreateLineItem(lineItemGroupId, lineItemId, reducerData)
        .then(fieldsToReplace => {
          if (fieldsToReplace) {
            dispatch({
              type: 'resetInvoiceEditorData',
              payload: fieldsToReplace
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  const updateLineItemGroup = useCallback(
    (
      lineItemGroupId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      props
        .onUpdateLineItemGroup(lineItemGroupId, reducerData)
        .then(fieldsToReplace => {
          if (fieldsToReplace) {
            dispatch({
              type: 'resetInvoiceEditorData',
              payload: fieldsToReplace
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  const updateLineItem = useCallback(
    (
      lineItemGroupId: string,
      lineItemId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      props
        .onUpdateLineItem(lineItemGroupId, lineItemId, reducerData)
        .then(fieldsToReplace => {
          if (fieldsToReplace) {
            dispatch({
              type: 'resetInvoiceEditorData',
              payload: fieldsToReplace
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  const deleteLineItemGroup = useCallback(
    (
      lineItemGroupId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      props
        .onDeleteLineItemGroup(lineItemGroupId, reducerData)
        .then(fieldsToReplace => {
          if (fieldsToReplace) {
            dispatch({
              type: 'resetInvoiceEditorData',
              payload: fieldsToReplace
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  const deleteLineItem = useCallback(
    (
      lineItemGroupId: string,
      lineItemId: string,
      reducerData: InvoiceEditorReducerState['data']
    ) => {
      props
        .onDeleteLineItem(lineItemGroupId, lineItemId, reducerData)
        .then(fieldsToReplace => {
          if (fieldsToReplace) {
            dispatch({
              type: 'resetInvoiceEditorData',
              payload: fieldsToReplace
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateMemo = useCallback(
    debounce(
      (
        reducerState: InvoiceEditorReducerState['data'],
        newMemo: InvoiceModel['memo']
      ) => {
        props
          .onUpdateMemo(reducerState, newMemo)
          .then(fieldsToUpdate => {
            if (fieldsToUpdate) {
              dispatch({
                type: 'updateInvoiceEditorData',
                payload: fieldsToUpdate
              })
            }
          })
          .catch(e => Sentry.captureException(e))
      },
      1000
    ),
    []
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateDueDate = useCallback(
    debounce(
      (
        reducerState: InvoiceEditorReducerState['data'],
        newDueDate: string | undefined
      ) => {
        props
          .onUpdateDueDate(reducerState, newDueDate)
          .then(fieldsToUpdate => {
            if (fieldsToUpdate) {
              dispatch({
                type: 'updateInvoiceEditorData',
                payload: fieldsToUpdate
              })
            }
          })
          .catch(e => Sentry.captureException(e))
      },
      1000
    ),
    []
  )

  const updateMemo = useCallback(
    (
      reducerState: InvoiceEditorReducerState['data'],
      newMemo: InvoiceModel['memo']
    ) => {
      dispatch({
        type: 'updateInvoiceEditorData',
        payload: { invoice: { memo: newMemo } }
      })

      debouncedUpdateMemo(reducerState, newMemo)
    },
    [debouncedUpdateMemo]
  )

  const updateDueDate = useCallback(
    (
      reducerState: InvoiceEditorReducerState['data'],
      newDueDate: string | undefined
    ) => {
      debouncedUpdateDueDate(reducerState, newDueDate)
    },
    [debouncedUpdateDueDate]
  )

  const updatePurchaseOrderNumber = useCallback(
    (
      reducerState: InvoiceEditorReducerState['data'],
      newPurchaseOrderNumber: string | undefined
    ) => {
      props
        .onUpdatePurchaseOrderNumber(reducerState, newPurchaseOrderNumber)
        .then(fieldsToUpdate => {
          if (fieldsToUpdate) {
            dispatch({
              type: 'updateInvoiceEditorData',
              payload: fieldsToUpdate
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  const updateReference = useCallback(
    (
      reducerState: InvoiceEditorReducerState['data'],
      newReference: string | undefined
    ) => {
      props
        .onUpdateReference(reducerState, newReference)
        .then(fieldsToUpdate => {
          if (fieldsToUpdate) {
            dispatch({
              type: 'updateInvoiceEditorData',
              payload: fieldsToUpdate
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  const showPaymentDetailsDrawer = useCallback(() => {
    props.onShowPaymentDetailsDrawer()
  }, [props])

  const showInvoicePdfPreviewDrawer = useCallback(() => {
    props.onShowInvoicePdfPreviewDrawer()
  }, [])

  const showEditCustomerForm = useCallback(() => {
    props.onShowEditCustomerForm()
  }, [props])

  const linkCustomerToIntegration = useCallback(
    (integrationService: IntegrationService) => {
      props
        .onLinkCustomerToIntegration(integrationService)
        .then(fieldsToUpdate => {
          if (fieldsToUpdate) {
            dispatch({
              type: 'updateInvoiceEditorData',
              payload: fieldsToUpdate
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  const syncInvoiceToIntegration = useCallback(
    (
      integrationService: IntegrationService,
      existingLinkedServices: InvoiceEditorReducerState['data']['invoice']['linkedServices']
    ) => {
      props
        .onSyncInvoiceToIntegration(integrationService, existingLinkedServices)
        .then(fieldsToUpdate => {
          if (fieldsToUpdate) {
            dispatch({
              type: 'updateInvoiceEditorData',
              payload: fieldsToUpdate
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  const addCollection = useCallback(
    (
      paymentProvider: PaymentProvider,
      existingPaymentOptions: InvoiceEditorReducerState['data']['invoice']['paymentOptions']
    ) => {
      props
        .onAddCollection(paymentProvider, existingPaymentOptions)
        .then(fieldsToUpdate => {
          if (fieldsToUpdate) {
            dispatch({
              type: 'updateInvoiceEditorData',
              payload: fieldsToUpdate
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    [props]
  )

  const updateCustomer = useCallback(
    (updatedCustomer: AdapterCustomerWithTaxModel) => {
      props
        .onUpdateCustomer(updatedCustomer)
        .then(fieldsToUpdate => {
          if (fieldsToUpdate) {
            dispatch({
              type: 'updateInvoiceEditorData',
              payload: fieldsToUpdate
            })
          }
        })
        .catch(e => Sentry.captureException(e))
    },
    []
  )

  const createCreditNote = useCallback(() => {
    props
      .onCreateCreditNote(
        state.data.invoice.currency,
        state.data.recipient.customerId
      )
      .then(response => {
        if (response) {
          void props.onUpdateCreditNoteLineItems(response.creditNote.id)
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [props, state.data.invoice.currency, state.data.recipient.customerId])

  const sendTestInvoice = useCallback(() => {
    props.onSendTestInvoice().catch(e => Sentry.captureException(e))
  }, [])

  return {
    data: state.data,
    configuration: state.configuration,
    derived: state.derived,
    editor: state.editor,
    functions: {
      addCollection,
      createCreditNote,
      createLineItemGroup,
      createLineItem,
      deleteLineItem,
      deleteLineItemGroup,
      downloadInvoice: () => {},
      finaliseAndSendInvoice,
      finaliseInvoice,
      hasInitializationDataChanged,
      linkCustomerToIntegration,
      loadData,
      recalculateInvoice,
      resetData,
      saveInvoice: props.onSave,
      sendInvoice,
      sendPaymentReminder,
      sendTestInvoice,
      showEditCustomerForm,
      showPaymentDetailsDrawer,
      syncInvoiceToIntegration,
      showInvoicePdfPreviewDrawer,
      updateCustomer,
      updateData,
      updateLineItemGroup,
      updateLineItem,
      updatePaymentStatus,
      voidInvoice,
      updateMemo,
      updateDueDate,
      updatePurchaseOrderNumber,
      updateReference
    }
  }
}
