import { Grid, GridItem } from '@chakra-ui/react'
import { JournalReportSummary } from './JournalReportSummary'
import { JournalReportActions } from './JournalReportActions'
import { JournalReportTable } from './JournalReportTable'

export const JournalReportDetailContent = () => {
  return (
    <Grid
      gridTemplateRows="auto auto 1fr"
      padding="16px"
      paddingBottom="0"
      rowGap="16px"
      height="100%"
      overflow="hidden"
    >
      <JournalReportSummary />
      <JournalReportActions />
      <GridItem overflow="auto" paddingBottom="16px">
        <JournalReportTable />
      </GridItem>
    </Grid>
  )
}
