import { FC, ReactNode } from 'react'
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import LinkSlashIcon from '@heroicons/react/16/solid/LinkSlashIcon'
import {
  GreyGrey10,
  GreyGrey40,
  GreyGrey60,
  GreyGrey80,
  GreyWhite,
  Lato13Bold,
  Lato13Regular,
  ShadowXs
} from '@sequencehq/design-tokens'
import {
  Button,
  Modal,
  ModalContextProvider,
  Tooltip
} from '@sequencehq/core-components'
import { ArrowUpRightIcon } from '@heroicons/react/16/solid'

export const ExistingEntityLink: FC<{
  externalEntityLabel: string
  serviceName: string
  unlinkModal: ReactNode
  linkedEntity: {
    href?: string
    canUnlink: boolean
    label: string
  }
  'data-testid'?: string
}> = props => {
  const hasLink = Boolean(props.linkedEntity.href)

  return (
    <Grid
      gridTemplateColumns={'1fr auto'}
      gridRowGap={'12px'}
      gridTemplateAreas={`
        "description unlink"
        ${hasLink ? '"view view"' : ''}
      `}
      cursor="default"
      gridColumnGap={'8px'}
      borderRadius="8px"
      background={GreyWhite}
      boxShadow={ShadowXs}
      padding="12px"
      backgroundColor={GreyGrey10}
      data-testid={props['data-testid']}
    >
      <GridItem
        gridArea="description"
        display="flex"
        flexDirection="column"
        gap="4px"
      >
        <Box {...Lato13Bold} color={GreyGrey80} textTransform="capitalize">
          {props.externalEntityLabel}
        </Box>
        <Box {...Lato13Regular} color={GreyGrey60}>
          {props.linkedEntity.label}
        </Box>
      </GridItem>
      <GridItem gridArea="unlink">
        {props.linkedEntity.canUnlink ? (
          <ModalContextProvider>
            <Modal.Trigger>
              <Tooltip label={`Unlink ${props.externalEntityLabel}`}>
                <LinkSlashIcon
                  color={GreyGrey60}
                  data-testid={`${props.externalEntityLabel}.unlink`}
                  height="16px"
                  width="16px"
                  cursor="pointer"
                />
              </Tooltip>
            </Modal.Trigger>
            {props.unlinkModal}
          </ModalContextProvider>
        ) : (
          <Tooltip label={`Cannot unlink ${props.externalEntityLabel}`}>
            <LinkSlashIcon
              data-disabled={true}
              color={GreyGrey40}
              data-testid={`${props.externalEntityLabel}.unlink`}
              height="16px"
              width="16px"
            />
          </Tooltip>
        )}
      </GridItem>
      {Boolean(props.linkedEntity.href) && (
        <GridItem gridArea="view">
          <a href={props.linkedEntity.href} target="_blank" rel="noreferrer">
            <Button variant="secondary" style={{ width: '100%' }}>
              <Flex alignItems="center" gap="8px">
                View in {props.serviceName}
                <ArrowUpRightIcon
                  height="16px"
                  width="16px"
                  color={GreyGrey80}
                />
              </Flex>
            </Button>
          </a>
        </GridItem>
      )}
    </Grid>
  )
}
