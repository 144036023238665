import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { RevRevHome, RevRevReportDetail } from '.'
import { RevRecRoot } from './view/RevenueRecognition.root'
import { apiQueryClient } from 'features/api/apiQueryClient'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { LoaderFunction } from 'react-router-dom'
import { Currency } from '@sequencehq/api/utils'

/**
 * This timeout function will not 'cancel' the request being made by the API package,
 * it's instead used to give us a maximum wait time for our loader, before we
 * give up and continue with the standard load. This doesn't impact the total
 * load time, but we don't want to leave the user waiting for too long after a
 * click before responding to them.
 * @param timeout
 * @returns
 */
const requestWithTimeout =
  (timeout: number) =>
  (request: Promise<unknown>): Promise<unknown> =>
    Promise.race([request, new Promise(res => setTimeout(res, timeout))])

const loadDefaultCurrency = async (): Promise<Currency> => {
  const currency = (
    await apiQueryClient.fetchQuery({
      queryKey: dashboardv20240509Client.getSequenceAccountSettings.queryKey,
      queryFn: async () => {
        return (
          await dashboardv20240509Client.getSequenceAccountSettings.request()
        )?.data
      },
      staleTime: 60000
    })
  )?.enabledCurrencies[0]

  return currency ?? 'GBP'
}

/**
 * The routes for the revenue recognition module. These are designed to be included
 * in the top level routers, such as dashboardApp.router.tsx.
 *
 * @param basePath
 * @returns
 */
export const revenueRecognitionRoutes = (basePath = 'revenue-recognition') => [
  {
    path: basePath,
    element: <RevRecRoot />,
    loader: async () => {
      /**
       * These routers have a 'loader' mechanism - this data is designed
       * to load the critical path data (with a timeout) for the page before we navigate.
       * We don't access this data directly - it instead if used to pre-populate the API
       * cache with fresh data.
       *
       * This reduces the 'flash' of loading seen when moving to a page, unless our API calls
       * are particularly slow.
       */

      /**
       * Preloads the default currency selection.
       */
      await requestWithTimeout(500)(loadDefaultCurrency())

      return true
    },
    children: [
      {
        path: 'report-detail',
        element: <RevRevReportDetail />,
        loader: async ({ request }: Parameters<LoaderFunction>[0]) => {
          const searchParams = new URL(request.url).searchParams
          const params = {
            from: searchParams.get('startDate'),
            to: searchParams.get('endDate'),
            currency: searchParams.get('currency')
          }

          if (Object.values(params).includes(null)) {
            return true
          }

          await requestWithTimeout(500)(
            /**
             * Preload the journal data, which drives both the expanded
             * and collapsed views.
             */
            apiQueryClient.prefetchQuery({
              queryKey: [
                ...dashboardv99990101Client.getJournals.queryKey,
                params
              ],
              queryFn: async () => {
                return (
                  await dashboardv99990101Client.getJournals.request({
                    from: params.from as string,
                    to: params.to as string,
                    currency: params.currency as Currency
                  })
                )?.data
              },
              staleTime: 5000
            })
          )

          return true
        }
      },
      {
        index: true,
        element: <RevRevHome />,
        loader: async ({ request }: Parameters<LoaderFunction>[0]) => {
          await requestWithTimeout(500)(
            Promise.all([
              /**
               * Preload the accounting periods
               */
              apiQueryClient.prefetchQuery({
                queryKey:
                  dashboardv99990101Client.getAccountingPeriods.queryKey,
                queryFn: async () => {
                  return (
                    await dashboardv99990101Client.getAccountingPeriods.request()
                  )?.data
                },
                staleTime: 60000
              }),
              /**
               * Preload graph data - which relies on the currency selection.
               */
              (async () => {
                const selectedCurrency =
                  new URL(request.url).searchParams.get('currency') ??
                  (await loadDefaultCurrency())
                await apiQueryClient.prefetchQuery({
                  queryKey: [
                    ...dashboardv99990101Client.getRevRecChartsData.queryKey,
                    { currency: selectedCurrency }
                  ],
                  staleTime: 5000,
                  queryFn: async () => {
                    return (
                      await dashboardv99990101Client.getRevRecChartsData.request(
                        {
                          currency: selectedCurrency as Currency
                        }
                      )
                    )?.data
                  }
                })
              })()
            ])
          )

          return true
        }
      }
    ]
  }
]
