import { StandardLayout } from '@sequencehq/core-components'
import { RevRevHomeContent } from './content/RevRecHomeContent'
import { RevRecHomeHeader } from './RevRecHomeHeader'

export const RevRevHome = () => {
  return (
    <StandardLayout
      header={<RevRecHomeHeader />}
      content={<RevRevHomeContent />}
    />
  )
}
