import { useCallback } from 'react'
import {
  InvoiceModel,
  InvoicePaymentStatus,
  LineItemGroupModel,
  LineItemModel,
  PaymentProvider,
  IntegrationService,
  UsageDataResponseModel,
  InvoiceUsageItemGroup
} from '@sequencehq/core-models'
import { UNGROUPED_LINE_ITEM_GROUP_ID_PATTERN } from 'InvoiceEditor/domainManagement/invoiceEditor.constants'

import {
  useDeleteInvoicesByInvoiceLineItemGroupsByIdMutation,
  useDeleteInvoicesByInvoiceLineItemsByIdMutation,
  usePostInvoicesByIdFinalizeAndSendMutation,
  usePostInvoicesByIdFinalizeMutation,
  usePostInvoicesByIdRecalculateMutation,
  usePostInvoicesByIdSendMutation,
  usePostInvoicesByIdSendPaymentReminderMutation,
  usePutInvoicesByIdPaymentStatusMutation,
  usePostInvoicesByIdVoidMutation,
  usePostInvoicesByInvoiceLineItemGroupsMutation,
  usePostInvoicesByIdSendByTestEmailMutation,
  usePutInvoicesByInvoiceLineItemGroupsByIdMutation,
  usePostInvoicesByInvoiceLineItemsMutation,
  usePutInvoicesByInvoiceLineItemsByIdMutation,
  usePutInvoicesByIdMutation
} from 'features/api'
import {
  usePostApiIntegrationsServiceSyncInvoiceInvoiceIdMutation,
  usePostInvoiceSettingsMutation
} from 'features/api/integratedApi.generated'
import { InvoiceEditorReducerState } from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import { invoiceEditorApiAdapter } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { useLoadLineItemsAndGroups } from 'InvoiceEditor/hooks/useLoadLineItemsAndGroups'
import { useLoadUsageData } from 'InvoiceEditor/hooks/useLoadUsageData'
import { useLoadSubAccountUsage } from 'InvoiceEditor/hooks/useLoadSubAccountUsage'

type CreateLineItemGroupData = {
  title: string
}

type CreateLineItemData = {
  title: string
  description: string | undefined
  quantity: string
  rate: string
  taxRate: string
  rateDisplay: 'ABSOLUTE' | 'PERCENTAGE'
  externalIds: LineItemModel['externalIds']
}

type UpdateLineItemData = {
  id: string
  title: string
  description: string | undefined
  quantity: string
  rate: string
  taxRate: string
  rateDisplay: 'ABSOLUTE' | 'PERCENTAGE'
  externalIds: LineItemModel['externalIds']
}

type UpdateLineItemGroupData = {
  id: string
  title: string
}

export const useSaveInvoice = () => {
  const [putInvoiceMutator] = usePutInvoicesByIdMutation()
  const [sendInvoiceMutator] = usePostInvoicesByIdSendMutation()
  const [finaliseInvoiceMutator] = usePostInvoicesByIdFinalizeMutation()
  const [recalculateInvoiceMutator] = usePostInvoicesByIdRecalculateMutation()
  const [voidInvoiceMutator] = usePostInvoicesByIdVoidMutation()
  const [finaliseAndSendInvoiceMutator] =
    usePostInvoicesByIdFinalizeAndSendMutation()
  const [sendPaymentReminderMutator] =
    usePostInvoicesByIdSendPaymentReminderMutation()
  const [paymentStatusMutator] = usePutInvoicesByIdPaymentStatusMutation()
  const [createLineItemGroupMutator] =
    usePostInvoicesByInvoiceLineItemGroupsMutation()
  const [updateLineItemGroupMutator] =
    usePutInvoicesByInvoiceLineItemGroupsByIdMutation()
  const [deleteLineItemGroupMutator] =
    useDeleteInvoicesByInvoiceLineItemGroupsByIdMutation()
  const [createLineItemMutator] = usePostInvoicesByInvoiceLineItemsMutation()
  const [updateLineItemMutator] = usePutInvoicesByInvoiceLineItemsByIdMutation()
  const [deleteLineItemMutator] =
    useDeleteInvoicesByInvoiceLineItemsByIdMutation()
  const [sendTestInvoiceMutator] = usePostInvoicesByIdSendByTestEmailMutation()
  const [syncInvoiceMutator] =
    usePostApiIntegrationsServiceSyncInvoiceInvoiceIdMutation()
  const [createInvoiceSettingsMutator] = usePostInvoiceSettingsMutation()
  const { loadLineItemsAndGroups } = useLoadLineItemsAndGroups({
    invoiceId: undefined,
    async: true
  })
  const { loadSubAccountUsage } = useLoadSubAccountUsage({
    invoiceId: undefined,
    async: true
  })
  const { loadUsageData } = useLoadUsageData({
    invoiceId: undefined,
    lineItemGroups: [],
    async: true
  })

  const sendInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{
        invoice: InvoiceModel | null
        success: boolean
      }> => {
        const response = await sendInvoiceMutator({
          id: invoiceId
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [sendInvoiceMutator]
  )

  const finaliseInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await finaliseInvoiceMutator({
          id: invoiceId
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [finaliseInvoiceMutator]
  )

  const recalculateInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{
        invoice: InvoiceModel | null
        lineItems: LineItemModel[] | null
        lineItemGroups: LineItemGroupModel[] | null
        lineItemGroupUsage:
          | (UsageDataResponseModel & { lineItemGroupId: string })[]
          | null
        subAccountUsageBreakdown: InvoiceUsageItemGroup[] | null
        success: boolean
      }> => {
        // send request to BE to recalculate invoice
        const recalculateInvoiceResponse = await recalculateInvoiceMutator({
          id: invoiceId
        })

        // refresh line items and line item groups
        const [lineItemsAndGroupsResponse, subAccountUsageResponse] =
          await Promise.all([
            loadLineItemsAndGroups({
              invoiceId
            }),
            loadSubAccountUsage({ invoiceId })
          ])

        if (
          'error' in recalculateInvoiceResponse ||
          'error' in lineItemsAndGroupsResponse ||
          'error' in subAccountUsageResponse
        ) {
          return {
            invoice: null,
            lineItems: null,
            lineItemGroups: null,
            lineItemGroupUsage: null,
            subAccountUsageBreakdown: null,
            success: false
          }
        } else {
          const usageData = await loadUsageData({
            invoiceId,
            lineItemGroups: lineItemsAndGroupsResponse.data.lineItemGroups
          })

          return {
            invoice: recalculateInvoiceResponse.data.value() ?? null,
            lineItems: lineItemsAndGroupsResponse.data.lineItems,
            lineItemGroups: lineItemsAndGroupsResponse.data.lineItemGroups,
            lineItemGroupUsage: usageData.data,
            subAccountUsageBreakdown: subAccountUsageResponse,
            success: true
          }
        }
      },
    [
      loadLineItemsAndGroups,
      loadUsageData,
      recalculateInvoiceMutator,
      loadSubAccountUsage
    ]
  )

  const voidInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await voidInvoiceMutator({
          id: invoiceId
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [voidInvoiceMutator]
  )

  const finaliseAndSendInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        // TODO: what about invoice number?
        const response = await finaliseAndSendInvoiceMutator({
          id: invoiceId
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [finaliseAndSendInvoiceMutator]
  )

  const sendPaymentReminder = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await sendPaymentReminderMutator({
          id: invoiceId
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [sendPaymentReminderMutator]
  )

  const updatePaymentStatus = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        paymentStatus: InvoicePaymentStatus
      ): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await paymentStatusMutator({
          id: invoiceId,
          updateInvoicePaymentStatusEndpointRequestModel: {
            paymentStatus
          }
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [paymentStatusMutator]
  )

  const createLineItemGroup = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        lineItemGroup: CreateLineItemGroupData
      ): Promise<{
        lineItemGroup: LineItemGroupModel | null
        success: boolean
      }> => {
        const response = await createLineItemGroupMutator({
          invoice: invoiceId,
          createLineItemGroupEndpointCreateLineItemGroupRequestModel: {
            title: lineItemGroup.title
          }
        })

        if ('error' in response) {
          return {
            lineItemGroup: null,
            success: false
          }
        } else {
          return {
            lineItemGroup: response.data.value() ?? null,
            success: true
          }
        }
      },
    [createLineItemGroupMutator]
  )

  const createLineItem = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        lineItemGroupId: string,
        lineItem: CreateLineItemData
      ): Promise<{
        lineItem: LineItemModel | null
        success: boolean
      }> => {
        const response = await createLineItemMutator({
          invoice: invoiceId,
          createOrUpdateLineItemRequestModel: {
            groupId: lineItemGroupId.match(UNGROUPED_LINE_ITEM_GROUP_ID_PATTERN)
              ? undefined
              : lineItemGroupId,
            title: lineItem.title,
            description: lineItem.description,
            quantity: lineItem.quantity.toString(),
            rate: lineItem.rate,
            taxRate: lineItem.taxRate.toString(),
            rateDisplay: lineItem.rateDisplay,
            externalIds: lineItem.externalIds
          }
        })

        if ('error' in response) {
          return {
            lineItem: null,
            success: false
          }
        } else {
          return {
            lineItem: response.data.value() ?? null,
            success: true
          }
        }
      },
    [createLineItemMutator]
  )

  const updateLineItem = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        lineItemGroupId: string,
        lineItem: UpdateLineItemData
      ): Promise<{
        lineItem: LineItemModel | null
        success: boolean
      }> => {
        const response = await updateLineItemMutator({
          invoice: invoiceId,
          id: lineItem.id,
          createOrUpdateLineItemRequestModel: {
            groupId: lineItemGroupId.match(UNGROUPED_LINE_ITEM_GROUP_ID_PATTERN)
              ? undefined
              : lineItemGroupId,
            title: lineItem.title,
            description: lineItem.description,
            quantity: lineItem.quantity.toString(),
            rate: lineItem.rate,
            taxRate: lineItem.taxRate.toString(),
            rateDisplay: lineItem.rateDisplay,
            externalIds: lineItem.externalIds
          }
        })

        if ('error' in response) {
          return {
            lineItem: null,
            success: false
          }
        } else {
          return {
            lineItem: response.data.value() ?? null,
            success: true
          }
        }
      },
    [updateLineItemMutator]
  )

  const updateLineItemGroup = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        lineItemGroup: UpdateLineItemGroupData
      ): Promise<{
        lineItemGroup: LineItemGroupModel | null
        success: boolean
      }> => {
        const response = await updateLineItemGroupMutator({
          invoice: invoiceId,
          id: lineItemGroup.id,
          updateLineItemGroupEndpointUpdateLineItemGroupRequestModel: {
            title: lineItemGroup.title
          }
        })

        if ('error' in response) {
          return {
            lineItemGroup: null,
            success: false
          }
        } else {
          return {
            lineItemGroup: response.data.value() ?? null,
            success: true
          }
        }
      },
    [updateLineItemGroupMutator]
  )

  const deleteLineItem = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        lineItemId: string
      ): Promise<{
        success: boolean
      }> => {
        const response = await deleteLineItemMutator({
          invoice: invoiceId,
          id: lineItemId
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    [deleteLineItemMutator]
  )

  const deleteLineItemGroup = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        lineItemGroupId: string
      ): Promise<{
        success: boolean
      }> => {
        const response = await deleteLineItemGroupMutator({
          invoice: invoiceId,
          id: lineItemGroupId
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    [deleteLineItemGroupMutator]
  )

  const updateMemo = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newMemo: InvoiceModel['memo']
      ): Promise<{
        memo?: InvoiceModel['memo']
        success: boolean
      }> => {
        const response = await putInvoiceMutator({
          id: invoiceId,
          // @ts-expect-error Newer address model supports more states than the old one. We need to use the new API here.
          updateInvoiceEndpointUpdateInvoiceRequestModel: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            memo: newMemo
          }
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            memo: response.data.value()?.memo ?? '',
            success: true
          }
        }
      },
    [putInvoiceMutator]
  )

  const updateDueDate = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newDueDate: InvoiceModel['dueDate']
      ): Promise<{
        dueDate?: InvoiceModel['dueDate']
        success: boolean
      }> => {
        const response = await putInvoiceMutator({
          id: invoiceId,
          // @ts-expect-error Newer address model supports more states than the old one. We need to use the new API here.
          updateInvoiceEndpointUpdateInvoiceRequestModel: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            dueDate: newDueDate
          }
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            dueDate: response.data.value()?.dueDate ?? '',
            success: true
          }
        }
      },
    [putInvoiceMutator]
  )

  const updatePurchaseOrderNumber = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newPurchaseOrderNumber: InvoiceModel['purchaseOrderNumber']
      ): Promise<{
        purchaseOrderNumber?: InvoiceModel['purchaseOrderNumber']
        success: boolean
      }> => {
        const response = await putInvoiceMutator({
          id: invoiceId,
          // @ts-expect-error Newer address model supports more states than the old one. We need to use the new API here.
          updateInvoiceEndpointUpdateInvoiceRequestModel: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            purchaseOrderNumber: newPurchaseOrderNumber
          }
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            purchaseOrderNumber:
              response.data.value()?.purchaseOrderNumber ?? '',
            success: true
          }
        }
      },
    [putInvoiceMutator]
  )

  const updateReference = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newReference: InvoiceModel['reference']
      ): Promise<{
        reference?: InvoiceModel['reference']
        success: boolean
      }> => {
        const response = await putInvoiceMutator({
          id: invoiceId,
          // @ts-expect-error Newer address model supports more states than the old one. We need to use the new API here.
          updateInvoiceEndpointUpdateInvoiceRequestModel: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            reference: newReference
          }
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            reference: response.data.value()?.reference ?? '',
            success: true
          }
        }
      },
    [putInvoiceMutator]
  )

  const sendTestInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        email: string
      ): Promise<{ invoice?: InvoiceModel; success: boolean }> => {
        const response = await sendTestInvoiceMutator({
          id: invoiceId,
          testEmail: email
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            invoice: response.data.value(),
            success: true
          }
        }
      },
    [sendTestInvoiceMutator]
  )

  const syncToIntegration = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (service: IntegrationService): Promise<{ success: boolean }> => {
        const response = await syncInvoiceMutator({
          invoiceId: invoiceId,
          service: service
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    [syncInvoiceMutator]
  )

  const createInvoiceSettings = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        paymentProvider: PaymentProvider
      ): Promise<{ success: boolean }> => {
        const response = await createInvoiceSettingsMutator({
          createInvoiceSettingsEndpointCreateInvoiceSettingsRequestModel: {
            invoiceId,
            paymentProvider
          }
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    [createInvoiceSettingsMutator]
  )

  return {
    createInvoiceSettings,
    createLineItemGroup,
    createLineItem,
    deleteLineItem,
    deleteLineItemGroup,
    finaliseAndSendInvoice,
    finaliseInvoice,
    recalculateInvoice,
    sendInvoice,
    sendPaymentReminder,
    sendTestInvoice,
    syncToIntegration,
    updateLineItemGroup,
    updateLineItem,
    updatePaymentStatus,
    voidInvoice,
    updateMemo,
    updateDueDate,
    updatePurchaseOrderNumber,
    updateReference
  }
}
