import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useQuery } from '@sequencehq/api/dist/utils'
import { LoaderError } from 'InvoiceEditor/domainManagement/invoiceEditor.types'

export type Customer = DashboardApi20240730.GetCustomer.Customer

type UseLoadCustomer = (customerId: string | undefined) => {
  loading: boolean
  error?: LoaderError
  data?: {
    customer: Customer
  }
}

export const useLoadCustomer: UseLoadCustomer = customerId => {
  const fetchCustomer = useQuery(dashboard20240730Client.getCustomer, {
    id: customerId ?? ''
  })
  const customer = fetchCustomer.data
  const data = customer ? { customer } : undefined

  return {
    loading: fetchCustomer.isLoading,
    data
  }
}
