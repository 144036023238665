import { Box, Button, Circle, Flex, Text, useToast } from '@chakra-ui/react'
import {
  Badge,
  KebabMenu,
  MenuItemBuilder,
  Toast
} from '@sequencehq/core-components'
import { NotificationPolicyModel } from '@sequencehq/core-models'
import { toTitleCase } from '@sequencehq/formatters'
import { MultiTagField, noReturn, validateEmailItem } from '@sequencehq/forms'
import {
  ColumnConfig,
  VirtualTable,
  VirtualTableConfig
} from '@sequencehq/tables'
import { isEmailsList } from '@sequencehq/validation'
import { isErrorResponse } from 'components/Form'
import { NotificationPoliciesView } from 'components/NotificationSettings/types'
import SettingsTab from 'components/SettingsPage/SettingsTab'
import {
  TagTypes,
  useGetApiSelfQuery,
  useGetSequenceAccountsByIdQuery,
  useLazyGetApiNotificationsPoliciesQuery,
  usePutSequenceAccountsByIdMutation
} from 'features/api'
import { openOverlay } from 'features/overlay'
import { AppDispatch, useDispatch, useSelector } from 'features/store'
import arrayMutators from 'final-form-arrays'
import { flow } from 'fp-ts/lib/function'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { INITIAL_RESULT_SIZE } from 'lib/constants'
import { handleFormResponse } from 'lib/formValidation'
import { FC, memo, useMemo } from 'react'
import { Bell } from 'react-feather'
import { Form } from 'react-final-form'

const deleteBuilder: MenuItemBuilder<NotificationPolicyModel> = (
  policy: NotificationPolicyModel
) => {
  return {
    status: 'LIVE',
    label: 'Delete policy',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'deleteNotificationPolicyModal',
          data: {
            id: policy.id,
            name: policy.name
          }
        })
      )
  }
}

const menuItemBuilders: () => MenuItemBuilder<NotificationPolicyModel>[] =
  () => [deleteBuilder]

const tableConfig: (
  dispatch: AppDispatch,
  flags: {
    [key: string]: boolean
  }
) => VirtualTableConfig<NotificationPoliciesView> = (dispatch, flags) => {
  const columns: ColumnConfig<NotificationPolicyModel>[] = [
    {
      heading: 'Name',
      formatter: flow(({ name }) => (
        <Flex flexDirection="row" alignContent="center">
          <Circle backgroundColor="#F0F4F7" size="24px">
            <Bell color="#667085" width="14px" height="14px"></Bell>
          </Circle>
          <Box width="8px"></Box>
          <Text lineHeight="22px">{name}</Text>
        </Flex>
      ))
    },
    {
      heading: 'Recipients',
      formatter: flow(({ recipients }) => (
        <Flex flexDirection="row" flexWrap="wrap">
          {recipients.map(recipient => (
            <Flex flexDirection="row" key={recipient} flexWrap="nowrap">
              <Badge sentiment="neutral" size="md" margin="2px">
                {recipient}
              </Badge>
              <Box width="4px"></Box>
            </Flex>
          ))}
        </Flex>
      ))
    },
    {
      heading: 'Channel',
      formatter: flow(({ channel }) => (
        <Flex flexDirection="row" alignContent="center">
          <Text lineHeight="22px">{channel}</Text>
        </Flex>
      ))
    },
    {
      heading: 'Notification Types',
      formatter: flow(({ notificationTypes }) => (
        <>
          {notificationTypes ? (
            <Flex flexDirection="row" flexWrap="wrap">
              {notificationTypes.map(type => (
                <Flex flexDirection="row" key={type} flexWrap="nowrap">
                  <Badge sentiment="neutral" size="md" margin="2px">
                    {toTitleCase(type)}
                  </Badge>
                  <Box width="4px"></Box>
                </Flex>
              ))}
            </Flex>
          ) : (
            <Badge sentiment="info" size="md">
              All
            </Badge>
          )}
        </>
      ))
    }
  ]

  columns.push({
    width: '56px',
    sticky: true,
    contentTextAlign: 'center',
    formatter: flow(
      model => menuItemBuilders().map(builder => builder(model)),
      items => (
        <KebabMenu menuItems={items} renderListInPortal={true} flags={flags} />
      )
    )
  })

  return {
    columns
  }
}

type FormValues = {
  alertEmailAddresses: string[]
}

type AlertEmailFormProps = {
  sequenceAccountId: string
}

const AlertEmailForm: FC<AlertEmailFormProps> = memo(
  ({ sequenceAccountId }) => {
    const { data: sequenceAccountData } = useGetSequenceAccountsByIdQuery({
      id: sequenceAccountId
    })

    const [updateSequenceAccount] = usePutSequenceAccountsByIdMutation()

    const sequenceAccount = sequenceAccountData?.value()

    const toast = useToast()

    const initialEmails = useMemo(
      () => sequenceAccount?.alertEmailAddresses ?? [],
      [sequenceAccount]
    )

    return (
      <>
        {sequenceAccount && (
          <Form<FormValues>
            mutators={{
              ...arrayMutators
            }}
            initialValues={{
              alertEmailAddresses: [...initialEmails, '']
            }}
            onSubmit={async (values, form) => {
              const result = await updateSequenceAccount({
                id: sequenceAccount.id,
                updateSequenceAccountProductEndpointUpdateSequenceAccountRequest:
                  {
                    alertEmailAddresses: values.alertEmailAddresses.filter(
                      email => Boolean(email)
                    )
                  }
              })

              if (isErrorResponse(result)) {
                toast({
                  id: 'sequenceAccountUpdated',
                  position: 'bottom',
                  isClosable: true,
                  render: () => (
                    <Toast
                      type="error"
                      title={`Alert email update failed`}
                      isClosable={true}
                    ></Toast>
                  )
                })

                return handleFormResponse(result, form.getRegisteredFields())
              }

              toast({
                id: 'sequenceAccountUpdated',
                position: 'bottom',
                isClosable: true,
                render: () => (
                  <Toast
                    type="success"
                    title={`Alert email updated`}
                    isClosable={true}
                  ></Toast>
                )
              })
            }}
            render={({ handleSubmit, submitting }) => {
              return (
                <Flex
                  as="form"
                  px={5}
                  py={2}
                  flexDirection="column"
                  onSubmit={noReturn(handleSubmit)}
                  w="100%"
                >
                  <Box height="20px"></Box>
                  <Flex flexDirection="row">
                    <Box width="500px">
                      <MultiTagField
                        fieldName="alertEmailAddresses"
                        fieldLabel="Alert email addresses"
                        validateItem={validateEmailItem}
                        validate={isEmailsList({ permitEmpty: true })}
                        infoPopover={{
                          body: (
                            <Text textStyle="formHelperText" mb={2}>
                              Provide an alert address where Sequence can send
                              alerts if something goes wrong.
                            </Text>
                          )
                        }}
                      />
                    </Box>
                    <Box width="20px"></Box>
                    <Flex flexDirection="column">
                      <Box height="24px"></Box>
                      <Button
                        variant="primary"
                        type="submit"
                        isLoading={submitting}
                      >
                        Save
                      </Button>
                    </Flex>
                    <Flex grow="1"></Flex>
                  </Flex>
                </Flex>
              )
            }}
          ></Form>
        )}
      </>
    )
  }
)

const NotificationSettings: FC = memo(() => {
  const dispatch = useDispatch()
  const flags = useFlags()

  const { data: selfResponse } = useGetApiSelfQuery(undefined)

  const self = selfResponse?.value()

  const resetKey = useSelector(
    state => state.apiCaching.tags['NotificationPolicies']
  )

  return (
    <SettingsTab
      title="Manage Notification Policies"
      summarySection={
        <Flex flexDirection="row">
          <Circle backgroundColor="#F0F4F7" size="37px">
            <Bell color="#667085"></Bell>
          </Circle>
          <Box width="8px"></Box>
          <Flex flexDirection="column">
            <Box height="12px"></Box>
            <Text>
              Configure notifications sent by Sequence for your internal company
              use
            </Text>
          </Flex>
          <Flex grow="1"></Flex>
          <Button
            variant="primary"
            onClick={() =>
              dispatch(
                openOverlay({
                  content: 'createNotificationPolicyModal'
                })
              )
            }
          >
            New Policy
          </Button>
        </Flex>
      }
    >
      {self && (
        <AlertEmailForm
          sequenceAccountId={self.sequenceAccounts[0]}
        ></AlertEmailForm>
      )}
      <VirtualTable<NotificationPoliciesView, TagTypes>
        resetKey={resetKey}
        useLazyQuery={useLazyGetApiNotificationsPoliciesQuery}
        tableConfig={tableConfig(dispatch, flags)}
        noBorder={true}
        initialResultSize={INITIAL_RESULT_SIZE}
      />
    </SettingsTab>
  )
})

export default NotificationSettings
