import { Grid, GridItem } from '@chakra-ui/react'
import { JournalReportTable } from './table/JournalReportTable'
import { RevRecGraphs } from './graphs/RevRecGraphs'
import { ErrorBoundary } from '@sentry/react'
import PageError from 'components/ErrorPages/PageError'

export const RevRevHomeContent = () => {
  return (
    <Grid height="100%" gridTemplateRows="auto 1fr">
      <GridItem minHeight="318px">
        <ErrorBoundary fallback={<PageError />}>
          <RevRecGraphs />
        </ErrorBoundary>
      </GridItem>
      <GridItem overflow="auto">
        <JournalReportTable />
      </GridItem>
    </Grid>
  )
}
