import { InsightsUsageMetricResponse } from '@sequencehq/core-models'
import { StringDate } from '@sequencehq/utils'
import {
  useGetInsightsUsageMetricsByUsageMetricIdUsageDataQuery,
  useGetUsageMetricsQuery
} from 'features/api'
import { useMemo, useState } from 'react'

interface DateRange {
  start: StringDate
  end: StringDate
}

export const useUsageMetricsWidget = (dateRange: DateRange) => {
  const [usageMetricId, setUsageMetricId] = useState('')

  const { data: usageMetrics, isFetching: isOptionsLoading } =
    useGetUsageMetricsQuery({ limit: 1000 })

  const { data: usageMetricData, isFetching: isDataLoading } =
    useGetInsightsUsageMetricsByUsageMetricIdUsageDataQuery(
      {
        usageMetricId,
        dateFrom: dateRange.start,
        dateTo: dateRange.end
      },
      {
        skip: !usageMetricId
      }
    )

  const usageMetricDataValue =
    usageMetricData?.value() as InsightsUsageMetricResponse

  const { total, data } = useMemo(() => {
    if (!usageMetricDataValue || !usageMetricDataValue?.data) {
      return {
        total: 0,
        data: []
      }
    }

    const { total: usageTotal, usageData } = usageMetricDataValue.data

    return {
      total: Number(usageTotal) || 0,
      data: usageData.map(({ periodStart, amount }) => ({
        date: periodStart,
        value: Number(amount) || 0
      }))
    }
  }, [usageMetricDataValue])

  const unitTickValues = useMemo(() => {
    if (!data || data.length === 0) {
      return [0, 0]
    }

    const max = Math.max(...data.map(d => d.value))
    return [0, max / 2, max]
  }, [data])

  const usageMetricOptions = useMemo(() => {
    const options = usageMetrics?.value()?.items.map(({ id, name }) => ({
      value: id,
      label: name
    }))

    if (!usageMetricId && options && options.length > 0) {
      setUsageMetricId(options[0].value)
    }

    return options
  }, [usageMetricId, usageMetrics])

  const usageMetricTypeField = useMemo(
    () => ({
      value: usageMetricId,
      label: usageMetricOptions?.find(option => option.value === usageMetricId)
        ?.label,
      onChange: setUsageMetricId,
      options: usageMetricOptions || []
    }),
    [usageMetricId, usageMetricOptions, setUsageMetricId]
  )

  const chart = useMemo(
    () => ({
      data,
      total,
      unit: '',
      unitTickValues,
      tooltipFormat: (value: number) =>
        `${value.toLocaleString('en-US')} events`
    }),
    [data, total, unitTickValues]
  )

  return {
    isLoading: isDataLoading || isOptionsLoading,
    usageMetricTypeField,
    chart
  }
}
