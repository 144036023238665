import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Text,
  Tooltip,
  useToast,
  UseToastOptions
} from '@chakra-ui/react'
import ArrowDownTrayIcon from '@heroicons/react/16/solid/ArrowDownTrayIcon'
import CurrencyPoundIcon from '@heroicons/react/16/solid/CurrencyPoundIcon'
import ViewColumnsIcon from '@heroicons/react/16/solid/ViewColumnsIcon'
import CalendarIcon from '@heroicons/react/16/solid/CalendarIcon'
import { Badge, KebabMenu } from '@sequencehq/core-components'
import {
  InvoicePaymentStatus,
  InvoiceStatus,
  toInvoicePaymentStatusBadgeProps,
  toInvoiceStatusBadgeProps,
  toMoney
} from '@sequencehq/core-models'
import { borderColor } from '@sequencehq/core-theme'
import { GreyGrey80, Spacing8 } from '@sequencehq/design-tokens'
import { formatISODate, localDateWithFormat } from '@sequencehq/formatters'
import {
  MagicTableV2,
  MagicTableCell,
  MagicTableCellEmpty,
  MagicTableCellInvoiceStatus,
  MagicTableCellLink,
  useMagicTableInfiniteQuery
} from '@sequencehq/tables'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { InvoiceBulkActions } from 'components/InvoiceBulkActions'
import { useInvoicePdfDownload } from 'InvoiceEditor/components/Header/useInvoicePdfDownload'
import {
  dueAfterFilterOptions,
  dueBeforeFilterOptions,
  invoiceAfterFilterOptions,
  invoiceBeforeFilterOptions,
  paymentStatusFilterOptions,
  sentFilterOptions,
  statusFilterOptions
} from 'components/Invoices/Invoices'
import { InvoiceViewSelector } from 'components/Invoices/InvoicesViewSelector'
import { menuItemBuilders } from 'components/Invoices/menuItemBuilders'
import { useInvoicesCsvExport } from 'components/Invoices/useInvoicesCsvExport'
import EmptyState, { EmptyStateContent } from 'components/Loading/EmptyState'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSearchParams } from 'react-router-dom'
import { InvoicesViewSettingsHeader } from 'components/Invoices/InvoicesViewSettingsHeader'
import { useGetMagicTableFilterOptions } from 'lib/magicTableSupport/useGetMagicTableFilterOptions'
import { BanknotesIcon, IdentificationIcon } from '@heroicons/react/16/solid'
import { useEnabledCurrencies } from 'components/CurrencySettings/useCurrencies'
import { useMemo } from 'react'
import { InvoicesSegmentedFilters } from 'components/Invoices/InvoicesSegmentedFilters'
import { addPaddingToIntegrationLogo, getLogo } from 'lib/integrations/logos'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import {
  dashboardv20240509Client,
  Dashboardv20240509Api,
  WithPagination
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { InvoicesFilters, InvoicesPropFilters } from './types'

type Invoice = Dashboardv20240509Api.GetInvoices.Invoice
type InvoicesView = {
  model: Invoice
  controlFilters: InvoicesFilters
  propFilters: InvoicesPropFilters
}

export const InvoicesMagicTable = () => {
  const flags = useFlags()
  const toast = useToast()
  const { download: downloadCsvExport } = useInvoicesCsvExport()
  const { downloadPdf } = useInvoicePdfDownload()
  const [searchParams] = useSearchParams()

  const { infiniteQuery, setQueryParams } = useMagicTableInfiniteQuery<
    WithPagination<{ items: Invoice[] }>
  >(dashboardv20240509Client.getInvoices)

  const enabledCurrencyRes = useEnabledCurrencies()

  const currencyFilterOptions = useMemo(() => {
    if (enabledCurrencyRes.status === 'SUCCESS') {
      return enabledCurrencyRes.enabledCurrencies.map(currency => ({
        value: currency,
        label: currency
      }))
    }
    return []
  }, [enabledCurrencyRes])

  const successToast = (options: UseToastOptions) => {
    if (options.id) {
      toast(options)
    }
  }

  const { fetchCustomerNameOptions } = useGetMagicTableFilterOptions()

  if (enabledCurrencyRes.status !== 'SUCCESS') {
    return (
      <Center height="100%">
        <Spinner />
      </Center>
    )
  }

  const additionalToolbarComponentsV3 = flags?.segmentedFilters
    ? {
        additionalToolbarComponentsV3: {
          start: <InvoicesSegmentedFilters />
        }
      }
    : {}

  return (
    <CurrentUserId>
      {userId => (
        <>
          <MagicTableV2<InvoicesView>
            entityNamePlural="invoices"
            entityIcon={props => <BanknotesIcon {...props} />}
            sequenceUserId={userId}
            onQueryParamsChanged={setQueryParams}
            infiniteQuery={infiniteQuery}
            rowPath={(row: InvoicesView['model']) => `/invoices/${row.id}`}
            columns={[
              {
                id: 'grossTotal',
                header: 'Amount',
                accessorFn: row => ({
                  currency: row.currency,
                  value: row.grossTotal
                }),
                cell: value => (
                  <MagicTableCell
                    text={toMoney(
                      value.getValue<{
                        currency: Invoice['currency']
                        value: Invoice['grossTotal']
                      }>()
                    )}
                    textAlign={value.column.columnDef?.meta?.textAlign}
                  />
                ),
                enableSorting: true,
                meta: { textAlign: 'right' }
              },
              {
                id: 'currency',
                header: 'Currency',
                accessorKey: 'currency',
                cell: value => {
                  const currency = value.getValue<Invoice['currency']>()

                  return <MagicTableCell text={currency} />
                }
              },
              {
                id: 'status',
                header: 'Status',
                accessorFn: row => ({
                  invoiceStatus: row.status,
                  paymentStatus: row.paymentStatus
                }),
                cell: value => {
                  const { invoiceStatus, paymentStatus } = value.getValue<{
                    invoiceStatus: Invoice['status']
                    paymentStatus: Invoice['paymentStatus']
                  }>()

                  return (
                    <MagicTableCellInvoiceStatus
                      invoiceStatus={
                        invoiceStatus === 'VOID' ? 'VOIDED' : invoiceStatus
                      }
                      paymentStatus={paymentStatus}
                    />
                  )
                }
              },
              {
                id: 'invoiceNumber',
                header: 'Invoice #',
                accessorKey: 'invoiceNumber',
                cell: value => {
                  const invoiceNumber =
                    value.getValue<Invoice['invoiceNumber']>()

                  if (!invoiceNumber) {
                    return null
                  }

                  return <MagicTableCell text={invoiceNumber} />
                }
              },
              {
                id: 'customerLegalCompanyName',
                header: 'Customer',
                accessorFn: row => ({
                  customerId: row.customerId,
                  customerLegalCompanyName: row.customerLegalCompanyName,
                  isCustomerArchived: row.isCustomerArchived
                }),
                cell: value => {
                  const {
                    customerId,
                    customerLegalCompanyName,
                    isCustomerArchived
                  } = value.getValue<{
                    customerId: Invoice['customerId']
                    customerLegalCompanyName: Invoice['customerLegalCompanyName']
                    isCustomerArchived: Invoice['isCustomerArchived']
                  }>()

                  return (
                    <MagicTableCellLink
                      to={`/customers/${customerId}`}
                      label={`${customerLegalCompanyName}${
                        isCustomerArchived ? ' (archived)' : ''
                      }`}
                    />
                  )
                },
                enableSorting: true
              },
              {
                id: 'billingPeriod',
                header: 'Billing Period',
                accessorFn: row => ({
                  billingPeriod: row.billingPeriod
                }),
                cell: value => {
                  const { billingPeriod } = value.getValue<{
                    billingPeriod: Invoice['billingPeriod']
                  }>()

                  return (
                    <MagicTableCell
                      text={
                        billingPeriod
                          ? `${localDateWithFormat(
                              billingPeriod.start,
                              'd MMM'
                            )} to ${localDateWithFormat(
                              billingPeriod.endInclusive,
                              'd MMM yyyy'
                            )}`
                          : ''
                      }
                    />
                  )
                }
              },
              {
                id: 'issueDate',
                header: 'Sent',
                accessorKey: 'issueDate',
                cell: value => (
                  <MagicTableCell
                    text={
                      localDateWithFormat(
                        value.getValue<Invoice['issueDate']>(),
                        'd MMM yyyy'
                      ) || ''
                    }
                  />
                )
              },
              {
                id: 'dueDate',
                header: 'Due',
                accessorKey: 'dueDate',
                cell: value => (
                  <MagicTableCell
                    text={
                      localDateWithFormat(
                        value.getValue<Invoice['dueDate']>(),
                        'd MMM yyyy'
                      ) || ''
                    }
                  />
                )
              },
              {
                id: 'accountingDate',
                header: 'Date',
                accessorKey: 'accountingDate',
                cell: value => (
                  <MagicTableCell
                    text={
                      localDateWithFormat(
                        value.getValue<Invoice['accountingDate']>(),
                        'd MMM yyyy'
                      ) || ''
                    }
                  />
                ),
                enableSorting: true
              },
              {
                id: 'lastRecalculated',
                header: 'Last Recalculated',
                accessorKey: 'calculatedAt',
                cell: row => {
                  const calculatedAt = row.getValue<Invoice['calculatedAt']>()

                  return (
                    <MagicTableCell
                      text={
                        calculatedAt
                          ? formatDistanceToNow(new Date(calculatedAt), {
                              addSuffix: true
                            })
                          : ''
                      }
                    />
                  )
                }
              },
              {
                id: 'linkedServices',
                header: 'Links',
                accessorKey: 'linkedServices',
                cell: row => {
                  const linkedServices =
                    row.getValue<Invoice['linkedServices']>()

                  if (!linkedServices.length) {
                    return null
                  }

                  return (
                    <MagicTableCellEmpty>
                      <Flex gap="4px" overflow="hidden">
                        {linkedServices.map(linkedService => (
                          <Tooltip
                            key={linkedService.externalService}
                            label={linkedService.externalService.replace(
                              /_/g,
                              ''
                            )}
                          >
                            <Center
                              boxSize="24px"
                              bg={borderColor}
                              borderRadius="24px"
                            >
                              {getLogo(
                                linkedService.externalService,
                                addPaddingToIntegrationLogo(
                                  linkedService.externalService
                                )
                                  ? 18
                                  : undefined
                              )}
                            </Center>
                          </Tooltip>
                        ))}
                      </Flex>
                    </MagicTableCellEmpty>
                  )
                }
              }
            ]}
            kebabMenu={(model: Invoice, props) => (
              <KebabMenu
                isLazy
                menuItems={menuItemBuilders(successToast, downloadPdf)
                  .map(builder => builder(model))
                  .filter(builder => builder.status === 'LIVE')}
                renderListInPortal={true}
                flags={flags}
                {...props}
              />
            )}
            filters={[
              {
                type: 'multiSelect',
                paramName: 'customerId',
                label: 'Customer',
                icon: IdentificationIcon,
                options: [],
                optionsFunc: fetchCustomerNameOptions,
                format: (value: string) => <Text>{value}</Text>
              },
              {
                type: 'multiSelect',
                paramName: 'invoiceStatus',
                options: statusFilterOptions(),
                format: status => (
                  <Badge
                    {...toInvoiceStatusBadgeProps({
                      status: status as InvoiceStatus
                    })}
                  />
                ), // Remove casting once we've got support for multiple Value types in Filters
                label: 'Status',
                icon: ViewColumnsIcon,
                optionsSortFn: () => 0
              },
              {
                type: 'multiSelect',
                paramName: 'invoicePaymentStatus',
                options: paymentStatusFilterOptions(),
                format: status => (
                  <Badge
                    {...toInvoicePaymentStatusBadgeProps({
                      status: status as InvoicePaymentStatus
                    })}
                  />
                ), // Remove casting once we've got support for multiple Value types in Filters
                label: 'Payment status',
                icon: ViewColumnsIcon,
                optionsSortFn: () => 0
              },
              {
                type: 'multiSelect',
                paramName: 'invoiceCurrency',
                label: 'Currency',
                icon: CurrencyPoundIcon,
                options: currencyFilterOptions,
                format: currency => currency
              },
              {
                type: 'date',
                paramName: 'due',
                paramNameBefore: 'dueBefore',
                paramNameAfter: 'dueAfter',
                optionsBefore: dueBeforeFilterOptions,
                optionsAfter: dueAfterFilterOptions,
                format: (value, label) => label,
                label: 'Due',
                stringifyDate: date => formatISODate(date),
                icon: CalendarIcon
              },
              {
                type: 'date',
                paramName: 'sent',
                paramNameBefore: 'sentBefore',
                paramNameAfter: 'sentAfter',
                optionsBefore: sentFilterOptions,
                optionsAfter: sentFilterOptions,
                format: (value, label) => label,
                label: 'Sent',
                stringifyDate: date => formatISODate(date),
                icon: CalendarIcon
              },
              {
                type: 'date',
                paramName: 'invoice',
                paramNameBefore: 'invoiceBefore',
                paramNameAfter: 'invoiceAfter',
                optionsBefore: invoiceBeforeFilterOptions,
                optionsAfter: invoiceAfterFilterOptions,
                format: (value, label) => label,
                label: 'Invoice Date',
                stringifyDate: date => formatISODate(date),
                icon: CalendarIcon
              },
              {
                type: 'toggle',
                paramName: 'excludeZeroQuantity',
                label: 'Hide zero-usage invoices'
              }
            ]}
            emptyState={props => (
              <EmptyState emptyContent={emptyContent} {...props} />
            )}
            bulkActions={(
              selectedRows: Invoice[],
              clearSelectedRows: () => void
            ) => (
              <InvoiceBulkActions
                selectedRows={selectedRows}
                clearSelectedRows={clearSelectedRows}
                popoverOffset={[0, 40]}
              >
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                />
              </InvoiceBulkActions>
            )}
            additionalToolbarComponents={
              <>
                {downloadCsvExport.available && (
                  <Button
                    variant="secondary2"
                    marginRight={Spacing8}
                    onClick={() =>
                      downloadCsvExport.onClick(searchParams.toString())
                    }
                    height="36px"
                    color={GreyGrey80}
                  >
                    <Box marginRight="6px">
                      <ArrowDownTrayIcon
                        height={16}
                        width={16}
                        color={GreyGrey80}
                      />
                    </Box>
                    Export to CSV
                  </Button>
                )}
                <InvoiceViewSelector active="list" />
              </>
            }
            viewSettingsHeader={<InvoicesViewSettingsHeader />}
            {...additionalToolbarComponentsV3}
          />
        </>
      )}
    </CurrentUserId>
  )
}

const emptyContent: EmptyStateContent = {
  title: 'Create draft invoice',
  description:
    'Create a one time invoice for a customer. To auto-invoice customers, use billing schedules.',
  linkCopy: 'Learn more about invoicing & accounting integrations.',
  docLink: `https://docs.sequencehq.com/invoicing/invoicing-customers`,
  overlayPayload: {
    content: 'createInvoiceModal',
    data: {
      customer: undefined
    }
  },
  buttonCopy: 'Add new invoice'
}
