import { useAddCustomer } from 'components/Customers/hooks/useAddCustomer'
import { CreateCustomer } from 'components/Customers/types'
import { useNotifications } from 'lib/hooks/useNotifications'
import lodash from 'lodash'
import { useCallback } from 'react'
import { useAddTaxRegistration } from './useAddTaxRegistration'
import { apiQueryClient } from 'features/api/apiQueryClient'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

// TODO: in the future when we use the api hooks everywhere, we should get this for free
// and shouldn't need to manually invalidate things like this.
export const invalidateCustomersCache = () => {
  void apiQueryClient.invalidateQueries({
    queryKey: dashboard20240730Client.getCustomers.queryKey
  })
}

export const useCustomersRoot = () => {
  const notifications = useNotifications()
  const { addCustomer } = useAddCustomer()
  const { addTaxRegistration } = useAddTaxRegistration()

  const createCustomer = useCallback(
    async (customer: CreateCustomer) => {
      const filterEmptyValues = (obj: object): object => {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([, value]) => value !== '' && value !== undefined)
            .map(([key, value]) =>
              value && typeof value === 'object' && !Array.isArray(value)
                ? [key, filterEmptyValues(value as object)]
                : [key, value]
            )
        )
      }

      notifications.displayNotification('Creating customer...', {
        duration: 30000
      })

      const filteredCustomerBody = filterEmptyValues(customer) as CreateCustomer
      const customerBody = {
        ...lodash.omit(filteredCustomerBody, [
          'taxIdentifier',
          'taxCountry',
          'taxState',
          'addTaxRegistration'
        ])
      }

      try {
        const saveResult = await addCustomer(customerBody)

        if (!saveResult) {
          throw new Error('Something went wrong, please try again.')
        }

        if (customer.addTaxRegistration && !!customer.taxIdentifier) {
          const taxRegistrationBody = {
            taxIdentifier: customer.taxIdentifier,
            country: customer.taxCountry,
            state: ['US', 'CA'].includes(customer.taxCountry)
              ? customer.taxState
              : undefined,
            customerId: saveResult.id
          }

          const taxRegistrationResult = await addTaxRegistration(
            taxRegistrationBody
          )

          if (!taxRegistrationResult) {
            notifications.displayNotification(
              'Failed to create tax registration for customer',
              {
                type: 'error'
              }
            )
            return saveResult
          }
        }

        notifications.displayNotification('Customer created', {
          type: 'success'
        })

        invalidateCustomersCache()

        return saveResult
      } catch (e) {
        const error = e as Error
        notifications.displayNotification('Failed to create customer', {
          type: 'error',
          description: error.message
        })
      }
    },
    [notifications, addCustomer]
  )

  return {
    functions: {
      createCustomer
    }
  }
}
